import React from 'react';
import { Row, Col, Button, Image } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import "../home/home.css"
import "../about/about.css"
import "../features/features.css"
import home1 from "../../assets/images/home1.png"
import home2 from "../../assets/images/home2.png"
import home3 from "../../assets/images/home3.png"
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import { rooms1, rooms2, rooms3, rooms4, rooms5 } from '../../Data'
SwiperCore.use([Autoplay, Navigation]);

const HomeStay = () => {
    const home = [
        {
            price: ': 1.999.000 VNĐ/đêm',
            images: [home1, home2, home3],
            amenities: [
                '3 giường đơn (1m8*2m)',
                'Đệm cao cấp êm ái, thoáng khí.',
                'Đệm sưởi & chăn bổ sung (áp dụng cho mùa lạnh.)',
                'Quạt (áp dụng cho mùa nóng.)',
                'Ấm siêu tốc, máy sấy, giá treo quần áo, khăn tắm, dầu gội, sữa tắm.',
                'WC đèn sưởi',
                'Miễn phí nước & trà trong phòng.'
            ],
            checkin: 'Check in: 14:00 | Check out: 12:00',
            standard: 'Tiêu chuẩn: 6 khách/phòng (thêm khách phụ thu 100K)'
        }
    ];
    const rooms = [
        {
            name: 'PAXU 1',
            price: '899.000 VNĐ/đêm',
            images: rooms2,
            amenities: [
                '1 giường đơn (1m8*2m)',
                'Đệm cao cấp êm ái, thoáng khí.',
                'Đệm sưởi & chăn bổ sung (áp dụng cho mùa lạnh.)',
                'Quạt (áp dụng cho mùa nóng.)',
                'Ấm siêu tốc, máy sấy, giá treo quần áo, khăn tắm, dầu gội, sữa tắm.',
                'WC đèn sưởi',
                'Miễn phí nước & trà trong phòng.'
            ],
            checkin: 'Check in: 14:00 | Check out: 12:00',
            standard: 'Tiêu chuẩn: 2 khách/phòng (thêm khách phụ thu 100K)'
        },
        {
            name: 'PAXU 2',
            price: '899.000 VNĐ/đêm',
            images: rooms3,
            amenities: [
                '1 giường đơn (1m8*2m)',
                'Đệm cao cấp êm ái, thoáng khí.',
                'Đệm sưởi & chăn bổ sung (áp dụng cho mùa lạnh.)',
                'Quạt (áp dụng cho mùa nóng.)',
                'Ấm siêu tốc, máy sấy, giá treo quần áo, khăn tắm, dầu gội, sữa tắm.',
                'WC đèn sưởi',
                'Miễn phí nước & trà trong phòng.'
            ],
            checkin: 'Check in: 14:00 | Check out: 12:00',
            standard: 'Tiêu chuẩn: 2 khách/phòng (thêm khách phụ thu 100K)'
        },
        {
            name: 'PAXU 3',
            price: '899.000 VNĐ/đêm',
            images: rooms4,
            amenities: [
                '1 giường đơn (1m8*2m)',
                'Đệm cao cấp êm ái, thoáng khí.',
                'Đệm sưởi & chăn bổ sung (áp dụng cho mùa lạnh.)',
                'Quạt (áp dụng cho mùa nóng.)',
                'Ấm siêu tốc, máy sấy, giá treo quần áo, khăn tắm, dầu gội, sữa tắm.',
                'WC đèn sưởi',
                'Miễn phí nước & trà trong phòng.'
            ],
            checkin: 'Check in: 14:00 | Check out: 12:00',
            standard: 'Tiêu chuẩn: 2 khách/phòng (thêm khách phụ thu 100K)'
        },
        {
            name: 'PAXU 4',
            price: '899.000 VNĐ/đêm',
            images: rooms5,
            amenities: [
                '3 giường đơn (1m8*2m)',
                'Đệm cao cấp êm ái, thoáng khí.',
                'Đệm sưởi & chăn bổ sung (áp dụng cho mùa lạnh.)',
                'Quạt (áp dụng cho mùa nóng.)',
                'Ấm siêu tốc, máy sấy, giá treo quần áo, khăn tắm, dầu gội, sữa tắm.',
                'WC đèn sưởi',
                'Miễn phí nước & trà trong phòng.'
            ],
            checkin: 'Check in: 14:00 | Check out: 12:00',
            standard: 'Tiêu chuẩn: 2 khách/phòng (thêm khách phụ thu 100K)'
        }
    ];

    return (
        <div className='mt-[100px] md:mx-40 mx-3'>
            <div className="flex items-center justify-center mb-8">
                <div className="flex-grow border-t border-[#864622] border-[2px]"></div>
                <h2 className="mx-4 text-2xl font-bold text-[#864622]" style={{
                    fontFamily: 'Montserrat, sans-serif',
                    fontWeight: 'bold',
                    textAlign: 'left'
                }}>Home</h2>
                <div className="flex-grow border-t border-[#864622] border-[2px]"></div>
            </div>
            {home.map((room, index) => (
                <div key={index} className="mb-10 p-4">
                    <div className="-mx-4" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'relative' }}>
                        <div className="swiper-button-prev-custom" style={{ flex: '0 0 auto', marginRight: '10px' }}>
                            <button className="prev-btn" style={{
                                fontSize: '20px',
                                background: '#864622',
                                border: '2px solid #FFFFFF',
                                cursor: 'pointer',
                                width: '40px',
                                height: '40px',
                                borderRadius: '50%',
                                display: 'flex',
                                color: 'white',
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingBottom: '3px'
                            }}>
                                ←
                            </button>
                        </div>

                        <Swiper
                            spaceBetween={30}
                            slidesPerView={2}
                            navigation={{
                                prevEl: '.swiper-button-prev-custom',
                                nextEl: '.swiper-button-next-custom',
                            }}
                            loop={true}
                            autoplay={{
                                delay: 2000,
                                disableOnInteraction: false,
                            }}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                },
                                768: {
                                    slidesPerView: 2,
                                },
                                1024: {
                                    slidesPerView: 2,
                                }
                            }}
                            modules={[Autoplay, Navigation]}
                        >
                            {rooms1.map(({ img }, index) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <div className="h-[170px] md:h-[350px]">
                                            <Image src={img} />
                                        </div>
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                        <div className="swiper-button-next-custom" style={{ flex: '0 0 auto', marginLeft: '10px' }}>
                            <button className="prev-btn" style={{
                                fontSize: '20px',
                                background: '#864622',
                                border: '2px solid #FFFFFF',
                                cursor: 'pointer',
                                width: '40px',
                                height: '40px',
                                borderRadius: '50%',
                                display: 'flex',
                                color: 'white',
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingBottom: '3px'
                            }}>
                                →
                            </button>
                        </div>
                    </div>
                    <Row gutter={[16, 16]} className="mt-4" style={{ display: 'flex', alignItems: 'stretch' }}>
                        <Col xs={24} md={17} style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className="px-4 border border-[#864622] border-[2px] rounded-lg p-4" style={{ flex: 1 }}>
                                <h3 className="text-lg font-bold text-[#864622]" style={{
                                    fontFamily: 'Montserrat, sans-serif',
                                    fontWeight: 'bold',
                                    textAlign: 'left'
                                }}>Tiện ích</h3>
                                <ul className="list-disc pl-6 text-[#864622] text-[16px]">
                                    {room.amenities.map((amenity, idx) => (
                                        <li key={idx}>{amenity}</li>
                                    ))}
                                </ul>

                            </div>
                        </Col>
                        <Col xs={24} md={7} style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className="px-4 bg-[#864622] text-white rounded-lg p-4" style={{ flex: 1 }}>
                                <li className="mt-4 text-[16px]">{room.checkin}</li>
                                <li className="text-[16px]">{room.standard}</li>
                                <li className="text-[16px]">Giá bán: <span className='font-bold'>{room.price}</span></li>
                                <div className="flex justify-center mt-4">
                                    <Button type="primary" size="large" className="bg-white font-bold text-[#864622]">
                                        BOOK PHÒNG NGAY
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            ))}
            <h3 className="text-lg md:mx-20 font-bold text-[#864622]" style={{
                fontFamily: 'Montserrat, sans-serif',
                fontWeight: 'bold',
                textAlign: 'left'
            }}>Lưu ý: </h3>
            <ul className="list-disc md:mx-24 pl-6 text-[#864622] text-[16px] mb-8">
                <li>Nhà vệ sinh nhỏ, khép kín</li>
                <li>Phòng được giữ nguyên kiến trúc nhà cổ truyền thống.</li>
            </ul>
            <div className="flex items-center justify-center mb-8 mt-8">
                <div className="flex-grow border-t border-[#864622] border-[2px]"></div>
                <h2 className="mx-4 text-2xl font-bold text-[#864622]" style={{
                    fontFamily: 'Montserrat, sans-serif',
                    fontWeight: 'bold',
                    textAlign: 'left'
                }}>NHÀ PAXU</h2>
                <div className="flex-grow border-t border-[#864622] border-[2px]"></div>
            </div>
            {rooms.map((room, index) => (
                <div key={index} className="mb-10">
                    <div className="flex justify-center items-center mb-8">
                        <h2 className="text-2xl font-bold text-white bg-[#864622] px-4 py-2 rounded-full border border-[#864622]" style={{
                            fontFamily: 'Montserrat, sans-serif',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            paddingLeft: '25px',
                            paddingRight: '25px'
                        }}>
                            {room.name}
                        </h2>
                    </div>
                    <div className="-mx-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'relative' }}>
                        <div className="swiper-button-prev-custom" style={{ flex: '0 0 auto', marginRight: '10px' }}>
                            <button className="prev-btn" style={{
                                fontSize: '20px',
                                background: '#864622',
                                border: '2px solid #FFFFFF',
                                cursor: 'pointer',
                                width: '40px',
                                height: '40px',
                                borderRadius: '50%',
                                display: 'flex',
                                color: 'white',
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingBottom: '3px'
                            }}>
                                ←
                            </button>
                        </div>

                        <Swiper
                            spaceBetween={30}
                            slidesPerView={2}
                            navigation={{
                                prevEl: '.swiper-button-prev-custom',
                                nextEl: '.swiper-button-next-custom',
                            }}
                            loop={true}
                            autoplay={{
                                delay: 2000,
                                disableOnInteraction: false,
                            }}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                },
                                768: {
                                    slidesPerView: 2,
                                },
                                1024: {
                                    slidesPerView: 2,
                                }
                            }}
                            modules={[Autoplay, Navigation]}
                        >
                            {room.images.map(({ img }, index) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <div className="h-[170px] md:h-[350px]">
                                            <Image src={img} />
                                        </div>
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                        <div className="swiper-button-next-custom" style={{ flex: '0 0 auto', marginLeft: '10px' }}>
                            <button className="prev-btn" style={{
                                fontSize: '20px',
                                background: '#864622',
                                border: '2px solid #FFFFFF',
                                cursor: 'pointer',
                                width: '40px',
                                height: '40px',
                                borderRadius: '50%',
                                display: 'flex',
                                color: 'white',
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingBottom: '3px'
                            }}>
                                →
                            </button>
                        </div>
                    </div>

                    <Row gutter={[16, 16]} className="mt-4" style={{ display: 'flex', alignItems: 'stretch' }}>
                        <Col xs={24} md={17} style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className="px-4 border border-[#864622] border-[2px] rounded-lg p-4" style={{ flex: 1 }}>
                                <h3 className="text-lg font-bold text-[#864622]" style={{
                                    fontFamily: 'Montserrat, sans-serif',
                                    fontWeight: 'bold',
                                    textAlign: 'left'
                                }}>Tiện ích</h3>
                                <ul className="list-disc pl-6 text-[#864622] text-[16px]">
                                    {room.amenities.map((amenity, idx) => (
                                        <li key={idx}>{amenity}</li>
                                    ))}
                                </ul>
                            </div>
                        </Col>
                        <Col xs={24} md={7} style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className="px-4 bg-[#864622] text-white rounded-lg p-4" style={{ flex: 1 }}>
                                <li className="mt-4 text-[16px]">{room.checkin}</li>
                                <li className="text-[16px]">{room.standard}</li>
                                <li className="text-[16px]">Giá bán: <span className='font-bold'>{room.price}</span></li>
                                <div className="flex justify-center mt-4">
                                    <Button type="primary" size="large" className="bg-white font-bold text-[#864622]">
                                        BOOK PHÒNG NGAY
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            ))}
            <h3 className="text-lg md:mx-20 font-bold text-[#864622]" style={{
                fontFamily: 'Montserrat, sans-serif',
                fontWeight: 'bold',
                textAlign: 'left'
            }}>Lưu ý: </h3>
            <ul className="list-disc md:mx-24 pl-6 text-[#864622] mb-8">
                <li>Nhà vệ sinh nhỏ, khép kín</li>
                <li>Phòng được giữ nguyên kiến trúc nhà cổ truyền thống.</li>
            </ul>
        </div>
    );
};

export default HomeStay;
