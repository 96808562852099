import React from 'react';
import { Row, Col, Card } from 'antd';
import BacXiu from '../../assets/images/coffe/bacxiu'
import CoffeBodau from '../../assets/images/coffe/coffebodau'
import CoffeCam from '../../assets/images/coffe/coffecam'
import CoffeCotDua from '../../assets/images/coffe/coffecotdua'
import CoffeSuachua from '../../assets/images/coffe/coffesuachua'
import CoffeLatte from '../../assets/images/coffe/coffelatte'
import CoffeSua from '../../assets/images/coffe/coffesua'
import DenDa from '../../assets/images/coffe/denda'
import TraGungSa from '../../assets/images/tea/tragungxa'
import TraAtiso from '../../assets/images/tea/traatiso'
import TraCamQue from '../../assets/images/tea/tracamque'
import TraCamSa from '../../assets/images/tea/tracamsa'
import TraTaoBacHa from '../../assets/images/tea/trataobacha'
import TraDuaHau from '../../assets/images/tea/traduahau'
import TraTaoQue from '../../assets/images/tea/trataoque'
import TraCamGung from '../../assets/images/tea/tracamgung'
import TraHoaCuc from '../../assets/images/tea/trahoacuc'
import TraTaoDo from '../../assets/images/tea/trataodo'
import ChanhTuyet from '../../assets/images/ice/chanhtuyet'
import LatteDauBiec from '../../assets/images/ice/lattedaubiec'
import LiptonSua from '../../assets/images/ice/liptonsua'
import SinhTo from '../../assets/images/ice/sinhto'
import NuocEp from '../../assets/images/ice/nuocep'
import Matcha from '../../assets/images/ice/matcha'
import CacaoSua from '../../assets/images/ice/cacaosua'

const MenuCoffe = () => {
    const cafePhin = [
        { name: 'Bạc sỉu', image: <BacXiu /> },
        { name: 'Đen đá', image: <DenDa /> },
        { name: 'Cafe sữa', image: <CoffeSua /> },
        { name: 'Cafe latte', image: <CoffeLatte /> },
        { name: 'Cafe sữa chua', image: <CoffeSuachua /> },
        { name: 'Cafe cam', image: <CoffeCam /> },
        { name: 'Cafe cốt dừa', image: <CoffeCotDua /> },
        { name: 'Cafe bơ đậu', image: <CoffeBodau /> }
    ];

    const traItems = [
        { name: 'Trà gừng/sả/chanh', image: <TraGungSa /> },
        { name: 'Trà táo quế', image: <TraTaoQue /> },
        { name: 'Trà cam quế', image: <TraCamQue /> },
        { name: 'Trà cam gừng', image: <TraCamGung /> },
        { name: 'Trà hoa cúc', image: <TraHoaCuc /> },
        { name: 'Trà táo đỏ', image: <TraTaoDo /> },
        { name: 'Trà atiso cam', image: <TraAtiso /> },
        { name: 'Trà cam sả', image: <TraCamSa /> },
        { name: 'Trà táo bạc hà', image: <TraTaoBacHa /> },
        { name: 'Trà dứa hấu', image: <TraDuaHau /> }
    ];
    const iceItems = [
        { name: 'Chanh tuyết', image: <ChanhTuyet /> },
        { name: 'Latte đậu biếc', image: <LatteDauBiec /> },
        { name: 'Lipton sữa', image: <LiptonSua /> },
        { name: 'Matcha', image: <Matcha /> },
        { name: 'Sinh tố', image: <SinhTo /> },
        { name: 'Nước ép', image: <NuocEp /> },
        { name: 'Cacao sữa', image: <CacaoSua /> }
    ];

    return (
        <div className='mt-[100px] md:mx-40 mx-3'>
            <div className="flex items-center justify-center mb-8">
                <div className="flex-grow border-t border-[#864622] border-[2px]"></div>
                <h2 className="mx-4 text-2xl font-bold text-[#864622]"
                    style={{
                        fontFamily: 'Montserrat, sans-serif',
                        fontWeight: 'bold',
                        textAlign: 'left'
                    }}>Cafe Phin</h2>
                <div className="flex-grow border-t border-[#864622] border-[2px]"></div>
            </div>
            <Row gutter={[16, 16]}>
                {cafePhin.map((item, index) => (
                    <Col key={index} xs={12} sm={8} md={6} lg={4}>
                        <Card
                            // hoverable
                            cover={typeof item.image === 'string' ? (
                                <img alt={item.name} src={item.image} style={{ width: '100%', height: 'auto', maxHeight: '150px', objectFit: 'cover', backgroundColor: '#864622', marginBottom: '0' }} />
                            ) : (
                                React.cloneElement(item.image, { style: { width: '100%', height: 'auto', maxHeight: '150px', objectFit: 'cover', marginBottom: '0' } })
                            )}
                            style={{ textAlign: 'center', border: 'none', backgroundColor: 'transparent', padding: '0' }}
                        >
                            <Card.Meta
                                title={<div className='text-[#864622]' style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{item.name}</div>}
                                style={{ color: '#864622' }}
                            />
                        </Card>
                    </Col>
                ))}
            </Row>

            <div className="flex items-center justify-center mb-8 mt-8">
                <div className="flex-grow border-t border-[#864622] border-[2px]"></div>
                <h2 className="mx-4 text-2xl font-bold text-[#864622]"
                    style={{
                        fontFamily: 'Montserrat, sans-serif',
                        fontWeight: 'bold',
                        textAlign: 'left'
                    }}>Trà</h2>
                <div className="flex-grow border-t border-[#864622] border-[2px]"></div>
            </div>
            <Row gutter={[16, 16]}>
                {traItems.map((item, index) => (
                    <Col key={index} xs={12} sm={8} md={6} lg={4}>
                        <Card
                            // hoverable
                            cover={typeof item.image === 'string' ? (
                                <img alt={item.name} src={item.image} style={{ width: '100%', height: 'auto', maxHeight: '150px', objectFit: 'cover', backgroundColor: '#864622', marginBottom: '0' }} />
                            ) : (
                                React.cloneElement(item.image, { style: { width: '100%', height: 'auto', maxHeight: '150px', objectFit: 'cover', marginBottom: '0' } })
                            )}
                            style={{ textAlign: 'center', border: 'none', backgroundColor: 'transparent', padding: '0' }}
                        >
                            <Card.Meta
                                title={<div className='text-[#864622]' style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{item.name}</div>}
                                style={{ color: '#864622' }}
                            />
                        </Card>
                    </Col>
                ))}
            </Row>
            <div className="flex items-center justify-center mb-8 mt-8">
                <div className="flex-grow border-t border-[#864622] border-[2px]"></div>
                <h2 className="mx-4 text-2xl font-bold text-[#864622]"
                    style={{
                        fontFamily: 'Montserrat, sans-serif',
                        fontWeight: 'bold',
                        textAlign: 'left'
                    }}>Đồ lạnh</h2>
                <div className="flex-grow border-t border-[#864622] border-[2px]"></div>
            </div>
            <Row gutter={[16, 16]} className='mb-20'>
                {iceItems.map((item, index) => (
                    <Col key={index} xs={12} sm={8} md={6} lg={4}>
                        <Card
                            // hoverable
                            cover={typeof item.image === 'string' ? (
                                <img alt={item.name} src={item.image} style={{ width: '100%', height: 'auto', maxHeight: '150px', objectFit: 'cover', backgroundColor: '#864622', marginBottom: '0' }} />
                            ) : (
                                React.cloneElement(item.image, { style: { width: '100%', height: 'auto', maxHeight: '150px', objectFit: 'cover', marginBottom: '0' } })
                            )}
                            style={{ textAlign: 'center', border: 'none', backgroundColor: 'transparent', padding: '0' }}
                        >
                            <Card.Meta
                                title={<div className='text-[#864622]' style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{item.name}</div>}
                                style={{ color: '#864622' }}
                            />
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default MenuCoffe;
