import React, { useEffect, useState } from 'react';
import logo from '../../assets/logo_coffe.png';
import { links } from '../../Data';
import { Link } from 'react-router-dom';
import { FaStream } from 'react-icons/fa';
import './header.css';

const Header = () => {
    const [scrollHeader, setScrollHeader] = useState(false);
    const [showMenu, setShowMenu] = useState(false);

    const changeHeader = () => {
        if (window.scrollY >= 80) {
            setScrollHeader(true);
        } else {
            setScrollHeader(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeHeader);
        return () => window.removeEventListener('scroll', changeHeader);
    }, []);

    return (
        <header className={`${scrollHeader ? 'scroll-header' : 'scroll-header'} header`}>
            <nav className="nav container">
                <Link to="/" className="nav__logo">
                    <img src={logo} alt="Logo" className='nav__logo-img' />
                </Link>
                <div className={`${showMenu ? 'show-menu' : ''} nav__menu`}>
                    <ul className="nav__list">
                        {links.map(({ name, path }, index) => {
                            return (
                                <li className="nav__item gap-4" key={index}>
                                    <Link
                                        to={path}
                                        className="nav__link"
                                        onClick={() => setShowMenu(false)}
                                    >
                                        {name}
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div className="nav__toggle" onClick={() => setShowMenu(!showMenu)}>
                    <FaStream />
                </div>
            </nav>
        </header>
    );
}

export default Header;
