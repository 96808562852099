import React from 'react';
const News = () => {

    return (
        <div className='mt-[100px] md:mx-40 mx-3'>
            <div className="flex items-center justify-center mb-8">
                <div className="flex-grow border-t border-[#864622] border-[2px]"></div>
                <h2 className="mx-4 text-2xl font-bold text-[#864622]" style={{
                    fontFamily: 'Montserrat, sans-serif',
                    fontWeight: 'bold',
                    textAlign: 'left'
                }}>Tin tức</h2>
                <div className="flex-grow border-t border-[#864622] border-[2px]"></div>
            </div>
        </div>
    );
};

export default News;
