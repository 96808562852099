import React from 'react'
import "./contact.css"
import "../about/about.css"
import 'swiper/swiper-bundle.min.css';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
// Thêm module navigation
SwiperCore.use([Autoplay, Navigation]);
// Import Swiper styles
// import "swiper/css";
const Contact = () => {
    return (
        <div style={{ backgroundColor: 'white', marginBottom: '90px' }}>
            <section className='about section' id='about'>
                <h2 className="section__title title-left" style={{
                    fontFamily: 'Montserrat',
                    fontSize: '24px',
                    fontWeight: '700',
                    lineHeight: '29.26px',
                    textAlign: 'center',
                    color: '#864622',
                    // marginTop: '90px'
                }}>
                    LIÊN HỆ VỚI CHÚNG TÔI
                </h2>
                <div className="about__grid container grid">
                    <div className="about__img-wrapper">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d228.9158817830343!2d105.30941198897769!3d23.364542755381958!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x36cbe21c360aaa63%3A0x1635f3cdca2cb1ce!2zQ2FmZSBD4buxYyBC4bqvYw!5e0!3m2!1svi!2s!4v1727498446172!5m2!1svi!2s" width="100%"
                            height="400"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                    <div className="about__content">
                        <p className="about__description" style={{ color: '#864622', textAlign: 'justify' }}>
                            Facebook: Cafe Cực Bắc
                        </p>
                        <p className="about__description" style={{ marginTop: '10px', color: '#864622', textAlign: 'justify' }}>
                            Instagram/Tiktok: @cafecucbac
                        </p>
                        <p className="about__description" style={{ marginTop: '10px', color: '#864622', textAlign: 'justify' }}>
                            Hotline: 0981925303
                        </p>
                        <p className="about__description" style={{ marginTop: '10px', color: '#864622', textAlign: 'justify' }}>
                            Email: cafecucbac@gmail.com
                        </p>
                        <p className="about__description" style={{ marginTop: '10px', color: '#864622', textAlign: 'justify' }}>
                            Địa chỉ: Cafe Cực Bắc, Làng Lô Lô Chải, xã Lũng Cú, huyện Đồng Văn, tỉnh Hà Giang
                        </p>
                    </div>
                </div>
            </section >
        </div>

    )
}

export default Contact