import React from 'react'
import logo from '../../assets/logo_coffe.png'
import {
    FaRegEnvelope,
    FaCaretRight,
    FaFacebookF,
    FaTiktok,
    FaTwitter,
    FaLinkedinIn,
    FaInstagram,
    FaInstagramSquare
} from 'react-icons/fa'

import { AiOutlinePhone } from 'react-icons/ai'
import { MdOutlineLocationOn } from 'react-icons/md'
import './footer.css'

const Footer = () => {
    return <footer className="footer">
        <div className="footer__grid container grid">
            <div className="footer__content">
                <a href="/" className="footer__logo">
                    <img src={logo} alt="" className="footer__logo-img" />
                </a>
                <ul className="footer__contact">
                    <li className="footer__contact_item" style={{ color: "white" }}>
                        <AiOutlinePhone className='icon' color='white' /> 0981925303
                    </li>
                    <li className="footer__contact_item" style={{ color: "white" }}>
                        <MdOutlineLocationOn className='icon' color='white' /> Làng Lô Lô Chải, xã Lũng Cú, huyện Đồng Văn, tỉnh Hà Giang
                    </li>
                    <li className="footer__contact_item" style={{ color: "white" }}>
                        <FaRegEnvelope className='icon' color='white' /> cafecucbac@gmail.com
                    </li>
                </ul>
            </div>
            <div className="footer__content">
                {/* <h3 className="footer__title">Quick Links</h3> */}
                <ul className="footer__links">
                    <li>
                        <a href="/" className="footer__link">
                            <FaCaretRight className='icon' /> Về chúng tôi
                        </a>
                    </li>
                    <li>
                        <a href="/coffe" className="footer__link">
                            <FaCaretRight className='icon' /> Các sản phẩm
                        </a>
                    </li>
                    <li>
                        <a href="/contact" className="footer__link">
                            <FaCaretRight className='icon' /> Liên hệ
                        </a>
                    </li>
                    {/* <li>
                        <a href="#gallery" className="footer__link">
                            <FaCaretRight className='icon' /> Gallery
                        </a>
                    </li>
                    <li>
                        <a href="#team" className="footer__link">
                            <FaCaretRight className='icon' /> Team
                        </a>
                    </li>
                    <li>
                        <a href="#reservation" className="footer__link">
                            <FaCaretRight className='icon' /> Reservartion
                        </a>
                    </li> */}
                </ul>
            </div>
            {/* <div className="footer__content">
                <h3 className="footer__title">Thời gian mở cửa</h3>
                <div className="footer__opening-hour" style={{ backgroundColor: 'white' }}>
                    <ul className="opening_hour-list" >
                        <li className="opening_hour-item">
                            <span>Saturday:</span>
                            <span>09AM - 06PM</span>
                        </li>
                        <li className="opening_hour-item">
                            <span>monday:</span>
                            <span>09AM - 06PM</span>
                        </li>
                        <li className="opening_hour-item">
                            <span>Thuesday:</span>
                            <span>09AM - 06PM</span>
                        </li>
                        <li className="opening_hour-item">
                            <span>Wednesday:</span>
                            <span>09AM - 06PM</span>
                        </li>
                        <li className="opening_hour-item">
                            <span>Thursday:</span>
                            <span>09AM - 06PM</span>
                        </li>
                        <li className="opening_hour-item">
                            <span>Friday:</span>
                            <span>09AM - 06PM</span>
                        </li>
                        <li className="opening_hour-item">
                            <span>Sunday:</span>
                            <span>Closed</span>
                        </li>
                    </ul>
                </div>
            </div> */}
            <div className="footer__content">
                <h3 className="footer__title" style={{
                    fontFamily: 'Montserrat, sans-serif',
                    textAlign: 'left'
                }}>Bản tin</h3>
                <p className="footer_description" style={{ color: 'white' }}>Đăng ký bản tin mới của chúng tôi để nhận được cập nhật và tin tức mới nhất</p>
                <form action="" className="subscribe__form">
                    {/* <input type="email" placeholder='Email của bạn' className="form__input subscribe__input" style={{ backgroundColor: 'white' }} /> */}
                    <button className='btn btn--flex subscribe__btn mt-4'>
                        <FaRegEnvelope /> Đăng ký ngay
                    </button>
                </form>
            </div>
            <div className="footer__content">
                <div className="footer__socials" style={{ marginTop: '-20px' }}>
                    <h3 className="footer__social-follow"
                        style={{
                            fontFamily: 'Montserrat, sans-serif',
                            textAlign: 'left'
                        }}>Follow Us:</h3>
                    <div className="footer__social-links">
                        <a href="https://www.facebook.com/@cafecucbac/?mibextid=JRoKGi" target="_blank" rel="noopener noreferrer" className="footer__social-link">
                            <FaFacebookF />
                        </a>
                        <a href="https://www.instagram.com/caffecucbac?igsh=ZGF4MDN2ZWJ5aTZ2" target="_blank" rel="noopener noreferrer" className="footer__social-link">
                            <FaInstagram />
                        </a>
                        <a href="https://www.tiktok.com/@cfcucbac?_t=8q7dqXUg5vr&_r=1" target="_blank" rel="noopener noreferrer" className="footer__social-link">
                            <FaTiktok />
                        </a>
                        <a href="https://www.threads.net/@caffecucbac" target="_blank" rel="noopener noreferrer" className="footer__social-link">
                            {/* <FaInstagram /> */}
                            <svg xmlns="http://www.w3.org/2000/svg" height="14" width="12.25" viewBox="0 0 448 512" fill="white">
                                <path d="M331.5 235.7c2.2 .9 4.2 1.9 6.3 2.8c29.2 14.1 50.6 35.2 61.8 61.4c15.7 36.5 17.2 95.8-30.3 143.2c-36.2 36.2-80.3 52.5-142.6 53h-.3c-70.2-.5-124.1-24.1-160.4-70.2c-32.3-41-48.9-98.1-49.5-169.6V256v-.2C17 184.3 33.6 127.2 65.9 86.2C102.2 40.1 156.2 16.5 226.4 16h.3c70.3 .5 124.9 24 162.3 69.9c18.4 22.7 32 50 40.6 81.7l-40.4 10.8c-7.1-25.8-17.8-47.8-32.2-65.4c-29.2-35.8-73-54.2-130.5-54.6c-57 .5-100.1 18.8-128.2 54.4C72.1 146.1 58.5 194.3 58 256c.5 61.7 14.1 109.9 40.3 143.3c28 35.6 71.2 53.9 128.2 54.4c51.4-.4 85.4-12.6 113.7-40.9c32.3-32.2 31.7-71.8 21.4-95.9c-6.1-14.2-17.1-26-31.9-34.9c-3.7 26.9-11.8 48.3-24.7 64.8c-17.1 21.8-41.4 33.6-72.7 35.3c-23.6 1.3-46.3-4.4-63.9-16c-20.8-13.8-33-34.8-34.3-59.3c-2.5-48.3 35.7-83 95.2-86.4c21.1-1.2 40.9-.3 59.2 2.8c-2.4-14.8-7.3-26.6-14.6-35.2c-10-11.7-25.6-17.7-46.2-17.8H227c-16.6 0-39 4.6-53.3 26.3l-34.4-23.6c19.2-29.1 50.3-45.1 87.8-45.1h.8c62.6 .4 99.9 39.5 103.7 107.7l-.2 .2zm-156 68.8c1.3 25.1 28.4 36.8 54.6 35.3c25.6-1.4 54.6-11.4 59.5-73.2c-13.2-2.9-27.8-4.4-43.4-4.4c-4.8 0-9.6 .1-14.4 .4c-42.9 2.4-57.2 23.2-56.2 41.8l-.1 .1z" />
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <p className="copyright__text">
            &copy; Copyright 2024 <span style={{ color: '#FFFFFF', textDecoration: 'underline' }}>
                Cafe Cực Bắc
            </span> All Right Reserved.
        </p>
    </footer>
}

export default Footer